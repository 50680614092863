// composables/useGsapAnimations.ts
import type { GSAP } from "gsap/all";

interface GSAPAnimations {
  scrollToToday: () => void;
  initHighlights: () => void;
  initHeroAnimation: () => void;
}

export const useGsapAnimations = async (): Promise<GSAPAnimations> => {
  // Return no-op functions if we're not in the browser
  if (typeof window === "undefined") {
    return {
      scrollToToday: () => {},
      initHighlights: () => {},
      initHeroAnimation: () => {},
    };
  }

  try {
    // Load GSAP and plugins concurrently for better performance
    const [{ default: gsap }, { ScrollTrigger }, { ScrollToPlugin }] =
      await Promise.all([
        import("gsap"),
        import("gsap/ScrollTrigger"),
        import("gsap/ScrollToPlugin"),
      ]);

    // Register plugins
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

    // Configure default GSAP settings for better performance
    gsap.config({
      autoSleep: 60,
      force3D: true,
      nullTargetWarn: false,
    });

    // Highlight items animation
    const initHighlights = () => {
      const highlightItems = document.querySelectorAll("#highlights .item");
      if (!highlightItems.length) return;

      // Pre-set initial state for better performance
      gsap.set(highlightItems, { opacity: 0 });

      ScrollTrigger.create({
        trigger: "#highlights",
        start: "top 80%",
        once: true,
        onEnter: () => {
          gsap.to(highlightItems, {
            opacity: 1,
            duration: 0.8,
            stagger: 0.2, // Add staggered effect
          });
        },
      });
    };

    // Hero section animation
    const initHeroAnimation = () => {
      const heroSection = document.querySelector("#hero");
      const highlightSection = document.querySelector("#highlight");

      if (!heroSection || !highlightSection) return;

      const tl = gsap.timeline({
        defaults: {
          ease: "power2.out",
          duration: 1,
        },
      });

      // Animate hero elements using fromTo for precise control
      tl.fromTo(
        heroSection,
        { opacity: 0, y: 30 },
        { opacity: 1, y: 0, duration: 1 }
      ).fromTo(
        highlightSection,
        { opacity: 0, y: 30 },
        { opacity: 1, y: 0, duration: 1 },
        "-=0.5" // Overlaps the animations for a smooth transition
      );

      // Parallax effect
      ScrollTrigger.create({
        trigger: heroSection,
        start: "top top",
        end: "bottom top",
        scrub: true,
        onUpdate: (self) => {
          if (!heroSection) return;
          gsap.to(heroSection, {
            y: self.progress * 50,
            duration: 0.1,
            overwrite: "auto",
          });
        },
      });
    };

    // Scroll to today section with optimized animation
    const scrollToToday = () => {
      const todaySection = document.querySelector("#today");
      if (!todaySection) return;

      gsap.to(window, {
        duration: 1,
        scrollTo: {
          y: "#today",
          offsetY: 100,
          autoKill: true,
        },
        ease: "power2.inOut",
        onComplete: () => {
          // Clean up animation
          ScrollTrigger.refresh();
        },
      });
    };

    // Initialize ScrollTrigger for better scroll performance
    ScrollTrigger.config({
      limitCallbacks: true,
      ignoreMobileResize: true,
    });

    // Handle resize events efficiently
    const debouncedRefresh = debounce(() => {
      ScrollTrigger.refresh(true);
    }, 250);

    window.addEventListener("resize", debouncedRefresh);

    // Clean up function for the resize listener
    onBeforeUnmount(() => {
      window.removeEventListener("resize", debouncedRefresh);
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    });

    // Return all animation functions
    return {
      scrollToToday,
      initHighlights,
      initHeroAnimation,
    };
  } catch (error) {
    console.error("Error initializing GSAP:", error);
    // Return no-op functions in case of error
    return {
      scrollToToday: () => {},
      initHighlights: () => {},
      initHeroAnimation: () => {},
    };
  }
};

// Utility function for debouncing resize events
function debounce(func: Function, wait: number) {
  let timeout: NodeJS.Timeout;
  return function executedFunction(...args: any[]) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

// Export composable as default
export default useGsapAnimations;
